<template>
    <div id='contact' class='container page'>
        <h3 id="contact-us"><mark>Contact Us</mark></h3>

        <p> Tell us what you like about Chachi. Tell us what you would like us to improve. Tell us if you have any requests. Or just drop by to say hello. We would love to hear from you. :)</p>

        <p>If you prefer, you can also send us an email at <code>contact [at] chachi (dot) app</code> or <code>gurjot [at] chachi (dot) app</code>.</p>
    </div>

</template>


<script>
export default {
	name: 'Contact',
    metaInfo: {
        title: 'Contact',
        titleTemplate: '%s | Chachi',
    },
}
</script>

<style scoped>
</style>